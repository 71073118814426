import React from 'react'
// import Location from '../images/location.webp'

import transcript from '../assets/083023 Amtrak LD Fleet Public Hearing DC 1000AM_T.pdf'
import slides from '../assets/Amtrak Public Hearing Slides .pdf'


const Events = () => {
  return (
    <article id='event' className=' bg-[#F3F6F7] h-full pt-20 pb-24'>

      <header>
        <h2 className='sub-title'>Events</h2>
      </header>

      <div className='flex flex-col justify-center items-center 
           
      '>



        <figure className="image mb-16 shrink-0

            ">
          <video width="770" height="500" controls className='px-10 mx-auto
          '>
            <source className='' src="https://s3.eu-west-1.amazonaws.com/ads.digital-spring.co.uk/Amtrak/AMTRAK+public+hearing.mp4#t=0.001" type="video/mp4" />
          </video>
        </figure>

        <div className='max-w-[770px] px-10
         '>
          <h3 className='text-slate-800 text-[42px] font-semibold leading-[52px] mb-[28px]'>Public Hearing</h3>


          <p className='body-text'>Amtrak held a public hearing on August 30, 2023, in Washington, D.C. and online via ZOOM as a forum for members of the public to provide commentary on accessible train travel.
          </p>


          {/* <p className=' text-[#12334B] mt-14 mb-4 text-xl font-semibold'>Scroll down to read more</p> */}

          <p className='body-text mt-5'>The slides presented at the public hearing are available online:
          </p>

          <div className='flex justify-between
          max-[770px]:block 
          '>
          <a className='' href={slides} target="_blank" rel="noreferrer">
            <button className='button w-full pl-5 bg-[#115378] hover:bg-[#143656] mt-10
            min-[400px]:w-[338px] min-[400px]:text-xl
            min-[920px]:mt-[32px]
            ' type="submit"
              aria-describedby="Public hearing slides"
            >
              PUBLIC HEARING SLIDES
              <svg className='ml-16
              ' width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" aria-describedby="description" role="img">
                <title id="title">Right Chevron</title>
                <desc id="description">A chevron pointing right</desc>
                <path d="M0.26 0.579999H4.56L8.62 5L4.56 9.42H0.26L4.34 5L0.26 0.579999Z" fill="white" />
              </svg>
            </button>
          </a>

          <a className='' href={transcript} target="_blank" rel="noreferrer">
            <button className='button w-full pl-5 bg-[#115378] hover:bg-[#143656] mt-10
            min-[400px]:w-[338px] min-[400px]:text-xl
            min-[920px]:mt-[32px]
            ' type="submit"
              aria-describedby="Public hearing transcript"
            >
              PUBLIC HEARING TRANSCRIPT
              <svg className='ml-5
              min-[400px]:ml-5
              ' width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" aria-describedby="description" role="img">
                <title id="title">Right Chevron</title>
                <desc id="description">A chevron pointing right</desc>
                <path d="M0.26 0.579999H4.56L8.62 5L4.56 9.42H0.26L4.34 5L0.26 0.579999Z" fill="white" />
              </svg>
            </button>
          </a>
          </div>


          <p className='mt-12'><strong>NOTICE OF CORRECTION:</strong></p>
          <p>Amtrak would like to clarify two points presented at the Aug. 30 public hearing.</p>

          <p className='mt-5'><strong>CORRECTION 1 (Public Hearing Video Recording 52:46):</strong> It was stated that cars 5 and 6, accessible diner and accessible lounge cars, are fitted with one accessible space and one transfer seat plus storage space for wheeled mobility devices.</p>

          <p className='mt-5'>To clarify, the accessible diner (car 5) is mainly occupied by the galley area (crew space). The diner seating space starts in the last 1/3rd of car 5 and extends to the middle of car 6 where one accessible space and one transfer seat plus storage space for wheeled mobility devices are located. The second half of car 6 is fitted with lounge seating, which also includes one accessible space and one transfer seat plus storage space for wheeled mobility devices.</p>

          <p className='mt-5'><strong>CORRECTION 2 (Public Hearing Video Recording 59:24):</strong> It was stated that cars 4 and 5, accessible diner and accessible lounge cars, are fitted with one accessible space and one transfer seat plus storage space for wheeled mobility devices.</p>

          <p className='mt-5'>To clarify, the accessible diner (car 4) is fitted with two accessible spaces and two transfer seats, plus storage space for wheeled mobility devices, located at the right end of the car, close to the lounge. The accessible lounge (car 5) is fitted with one accessible space and one transfer seat, plus storage space for wheeled mobility devices</p>

        </div>
      </div>

      {/* <section className='bg-[#115378] h-full mt-[90px] text-white text-xl leading-tight tracking-wide py-10 px-10
      
      '>
        <div className='max-w-[1225px] mx-auto
        '>
          <ul className='list-disc'>
            <li className=' font-medium'>Please note that in-person attendance will be limited due to the size limitations of the room.</li>
            <li className=' my-4 font-medium'>Those who register to attend virtually will receive an email confirmation from Zoom video conferencing with the log-in information.</li>
          </ul>
        </div>
      </section> */}

    </article>
  )
}

export default Events