// import React, { useState, useRef } from "react";
// import FormTermsConditions from '../components/formTermsConditions'

const SubmitYourComment = () => {
    // const [result, setResult] = useState("");
    // const [registrationStatus, setRegistrationStatus] = useState("form");

    // const submitTextRef = useRef(null);

    // const [query, setQuery] = useState({
    //     name: "",
    //     message: ""
    // });

    // // Update inputs value
    // const handleParam = () => (e) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     setQuery((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };
    // // Form Submit function
    // const formSubmit = (e) => {
    //     e.preventDefault();
    //     setRegistrationStatus("success");
    //     submitTextRef.current?.focus();
    //     setResult("Sending....");
    //     const formData = new FormData();
    //     Object.entries(query).forEach(([key, value]) => {
    //         formData.append(key, value);
    //     });
    //     fetch("https://getform.io/f/5a099b7b-d2a3-4fcc-b911-677cb371ab9e", {
    //         method: "POST",
    //         body: formData
    //     }).then((response) => {
    //         setQuery({ name: "", email: "" })
    //         console.log('Form submitted successfully:', response);
    //         setResult("Thank you for submitting your comment");
    //     })
    //         .catch((error) => {
    //             console.error('Error submitting form:', error);
    //             setResult("Sorry, there's been an error. Please try again later");
    //         });
    // }
    return (
        <section id='form' className="text-inputs-section h-full pt-20 mb-14">
            <header>
                <h2 className='sub-title'>public commentary</h2>
            </header>

            <div className='max-w-[650px] mx-auto px-10'>

                <p className='body-text mb-16 text-center'>The public comment period has closed. Thank you to all who took the time to provide your comment to Amtrak on the future of accessible train travel. Amtrak welcomes your feedback at any time at <a href='https://www.amtrak.com/contact-us/email.html'
                    target="_blank"
                    aria-label="Click here to submit additional feedback or get in contact with Amtrak" className='text-[#3B7EA2] block underline'
                    rel="noreferrer">https://www.amtrak.com/contact-us/email.html.</a></p>

                {/* {registrationStatus === "form" && (

                    <form className='grid ' onSubmit={formSubmit} >
                        <p className='body-text mb-16'>The public comment period has been extended to 5 p.m. Eastern Time on September 8, 2023. To provide commentary to Amtrak on accessible train travel, enter comments below and click submit. By submitting your comments, you acknowledge that they are subject to disclosure to the extent required by law. <strong>Fields marked with an * are mandatory.</strong></p>

                        <label className='form-sub pl-1 mb-2 after:content-["*"] after:ml-0.5 after:text-red-500'
                            htmlFor="name">First and Last Name</label>
                        <input className='p-3 border'
                            aria-label="This full name field is required in order to submit a comment."
                            type="text"
                            name="name"
                            id="name"
                            placeholder='Your first and last name'
                            value={query.name}
                            onChange={handleParam()}
                            required />



                        <label className='form-sub pl-1 mb-2 mt-5 after:content-["*"] after:ml-0.5 after:text-red-500'
                            htmlFor="message">Your Comment</label>
                        <textarea className='p-3 border h-[250px]'
                            aria-label="This comment field is required in order to submit a comment."
                            name="message"
                            id="message"
                            placeholder='Write your comment under 10,000 characters here'
                            maxLength="10000"
                            value={query.message}
                            onChange={handleParam()}
                            required />


                        <FormTermsConditions />


                        <button className='button pl-3 ml-auto mt-10 bg-[#3B7DA2] hover:bg-[#143656]' type="submit"
                            aria-describedby="Submit Form"
                        // onClick={onOpen}
                        >

                            Submit now

                            <svg className='mx-2' width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" aria-describedby="description" role="img">
                                <title id="title">Right Chevron</title>
                                <desc id="description">A chevron pointing right</desc>
                                <path d="M0.26 0.579999H4.56L8.62 5L4.56 9.42H0.26L4.34 5L0.26 0.579999Z" fill="white" />
                            </svg>

                        </button>
                    </form>
                )}
                {registrationStatus === "success" && (
                    <div className='text-center'>
                        <header ref={submitTextRef} aria-describedby="submission-text" className='text-slate-800 tracking-wide font-bold text-[30px] mt-5 sm:text-[42px] sm:mt-20'>
                            {result}
                        </header>
                    </div>
                )} */}
            </div>
        </section>
    )
}

export default SubmitYourComment