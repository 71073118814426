import React from 'react'
import Logo from '../images/svg/amtrak-logo.svg'
import { useDisclosure } from '@chakra-ui/react';

import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'



const Navbar = () => {
    // const [nav, setNav] = useState(false)
    // const handleClick = () => setNav(!nav)

    // const handleLinkClick = () => setNav(false)

    function handleButtonClick(props) {
        onClose();
        setTimeout(() => {
            window.location.href = props;
        }, 400);

    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <nav className="w-full h-[60px] text-white text-sm font-black uppercase tracking-wide bg-gradient-to-r from-[#37779c] to-[#143656] shadow sticky top-0 z-10 pt-4
        " aria-label="Main Navigation"
        >

            <div className='mx-auto flex justify-between max-w-[1450px] px-5'>


                {/* Logo on the left */}
                <div className='py-2 sm:py-0'>
                    <a href="#home" className=''>
                        <img src={Logo} alt="Amtrak Logo" className='w-[180px]
                    sm:w-[225px]' />
                    </a>
                </div>

                {/* Links on the right */}
                <div role="navigation" className='mr-0 max-[800px]:hidden'>
                    <ul className='flex items-end h-full'>
                        <li className=''>
                            <a href="#about">About this project</a>
                        </li>
                        <li className='mx-[60px] '>
                            <a href="#event">events</a>
                        </li>
                        <li className=''>
                            <a href="#form">submit your comment</a>
                        </li>
                    </ul>
                </div>

                {/* hamburger menu */}

                <button ref={btnRef} onClick={onOpen} className='min-[800px]:hidden z-10'>
                    <svg className='' width="35px" height="35px" viewBox="-0.5 0 25 25" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"
                        aria-labelledby="title" aria-describedby="description" role="menu"
                    >
                        <title id="title">Small screens Menu</title>
                        <desc id="description">Three lines creating a "hamburger" menu icon</desc>
                        <path d="M2 12.32H22" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2 18.32H22" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2 6.32001H22" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>

                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                    finalFocusRef={btnRef}
                    size='full'
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        {/* <DrawerHeader></DrawerHeader> */}

                        <DrawerBody>
                            <div role="navigation" className='h-full pt-48'>
                                <ul className='text-white text-[35px] font-black uppercase tracking-wide text-start'>
                                    <li>
                                        <button className='uppercase mb-5' type="button" onClick={() => handleButtonClick("#home")} >home</button>
                                    </li>
                                    <hr />
                                    <li className='nav-lines'>
                                        <button className='uppercase my-5 text-start' type="button" onClick={() => handleButtonClick("#about")} >about this project</button>
                                    </li>
                                    <hr />
                                    <li className=''>
                                        <button className='uppercase my-5' type="button" onClick={() => handleButtonClick("#event")} href="#event">events</button>
                                    </li>
                                    <hr />
                                    <li>
                                        <button className='uppercase mt-5 text-start' type="button" onClick={() => handleButtonClick("#form")} href="#form">submit your comment</button>
                                    </li>
                                </ul>
                            </div>

                        </DrawerBody>


                        {/* <DrawerFooter></DrawerFooter> */}
                    </DrawerContent>
                </Drawer>

            </div>

        </nav>
    );
};

export default Navbar;
