import { Fragment } from 'react';
import './App.css';
import Navbar from './components/navbar';
import HeroImage from './components/heroImage';
import AboutThisProject from './pages/aboutThisProject';
import SubmitYourComment from './pages/submitYourComment';

import footerImage from './images/footer-image.webp'
import Footer from './components/footer';
import "./fonts/myriadPro.woff"
import Events from './pages/events';
import InPerson from './components/inPerson';
import FacilitationRequest from './components/facilitationRequest';


function App() {
  return (
    <Fragment>

      <Navbar />
      <HeroImage />
      <AboutThisProject />
      <FacilitationRequest />
      <Events />
      {/* <InPerson /> */}
      <section>
        <figure className="image h-[65vw]
            sm:[55vw]
            lg:h-[35vw]">
          <img className='object-cover w-full h-full' src={footerImage} alt="Amtrak's Southwest Chief train crosses a western U.S. landscape." />
        </figure>
      </section>
      <SubmitYourComment />
      <Footer />
    </Fragment>
  );
}

export default App;
