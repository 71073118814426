import React from 'react'
import trainImage from '../images/hero-image.webp'

const HeroImage = () => {
  return (
    <section id='home' className="intro-section">
      <figure className="image h-[65vw]
            sm:[55vw]
            lg:h-[35vw]">
        <img className='object-cover w-full h-full
        
        ' src={trainImage} alt="Amtrak's California Zephyr train travels along water and cliffs." />
      </figure>

      <div className='max-w-[770px] mx-auto px-10'>

        <header>
          <h1 className='text-slate-800 text-[42px] font-semibold leading-[52px] my-[28px]'>Amtrak’s Commitment to Accessibility</h1>
        </header>

        <p className='body-text'>

          Amtrak is committed to providing the best possible onboard experience to customers with disabilities. This is why Amtrak is preparing designs for new Long Distance trains offering features that will enhance accessibility.<br /><br />
          Our vision for the future of train travel includes many innovations that go beyond what is required by the Americans with Disabilities Act (ADA), and in some cases, designs would require approval of the Federal Railroad Administration (FRA) based on an equivalent facilitation determination. As part of this process, public feedback on accessible train travel is requested.


        </p>


        <p className='mx-auto text-[#12334B] text-center mt-14 mb-4 text-xl font-semibold'>scroll down to read more</p>


        <svg className='mx-auto animate-bounce' width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" aria-describedby="description" role="img">
          <title id="title">Down Chevron</title>
          <desc id="description">A chevron pointing down to scroll and read more</desc>
          <path d="M20.837 0.960999L20.837 11.066L10.45 20.607L0.0629998 11.066L0.0630003 0.960998L10.45 10.549L20.837 0.960999Z" fill="#F73C3C" />
        </svg>
      </div>


    </section>
  )
}

export default HeroImage