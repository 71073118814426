import React, { Fragment } from 'react'

const FacilitationRequest = () => {
    return (
        <Fragment>
            <section className='text-[18px] max-w-[770px] mx-auto px-10 mb-20
            min-[500px]:body-text
            '>
                <ol className='roman-styles parent'>

                    <li><strong>Introduction</strong>

                        <p>As we develop plans for procuring and deploying new rail equipment for overnight train service on our Long Distance network, Amtrak is committed to enhancing and improving the customer experience for customers with disabilities, including customers who use wheeled mobility devices. Many of today’s regulatory standards that define accessible rail cars do not provide a truly accessible journey. To ensure that all customers have equal access to the unique experiences offered by our Long Distance services, we need to re-imagine what is possible. Community engagement will play a critical role in this process.</p>

                        <p>In addition, new train designs and technologies offer opportunities to deliver a better and more accessible experience to customers with mobility disabilities. Strict compliance with existing regulations hinder some of these new alternatives.</p>

                        <p>After providing opportunities for the public to provide feedback, Amtrak will submit a request to the Federal Railroad Administration for approval to use alternative design standards that provide equal or better accessibility. This process is called “equivalent facilitation.”</p>

                        <p>Amtrak is dedicated to fostering an environment where everyone feels welcome, comfortable, and valued throughout their journey. Your input and feedback are welcomed as we continue to work to create a truly inclusive Long Distance train service and experience.</p>

                        <p>We appreciate your insights and feedback on our journey towards enhanced accessibility and inclusivity.</p>

                    </li>

                    <li><strong>Specific Provisions of Part 38 Concerning which Amtrak is Seeking a Determination of Equivalent Facilitation</strong>

                        <p>As stated above, Amtrak is seeking a determination of “equivalent facilitation” on several regulatory provisions that define standards of accessibility that are specific to individual rail cars (as opposed to entire trainsets). In lieu of strict compliance with the following provisions in 49 CFR Part 38, Amtrak proposes to use the Alternative Design Standards described below in Section III, which would meet or exceed the level of accessibility or usability of the train, as further described below in Section IV.</p>

                        <dl>
                            <dt><strong>38.111(a)(1)</strong> requires individual single-level rail passenger coaches to have a specific number of accessible spaces, regardless of the number or location of accessible spaces elsewhere on the train. It states:</dt>
                            <dd>(1) Single-level rail passenger coaches and food service cars (other than single-level dining cars) shall comply with §§ 38.113 through 38.123 of this part. Compliance with § 38.125 of this part shall be required only to the extent necessary to meet the requirements of paragraph (d) of this section.</dd>

                            <dt><strong>38.111(a)(4)</strong> requires individual bi-level lounge cars to be accessible on the lower level and to have accessible restrooms in the car, regardless of the accessibility of the upper level and regardless of the availability of accessible restrooms in other nearby accessible cars. It states:</dt>

                            <dd>Bi-level lounge cars shall have doors on the lower level, on each side of the car from which passengers board, complying with § 38.113, a restroom complying with § 38.123, and at least one space complying with § 38.125(d) (2) and (3) to provide table service to a person who wishes to remain in his or her wheelchair and space to fold and store a wheelchair for a person who wishes to transfer to an existing seat.</dd>

                            <dt><strong>38.111(a)(5)</strong> requires accessible restrooms in each individual single-level coach and food service car, as well as in each individual dining or lounge car if there is a restroom for other passengers, regardless of the availability of accessible restrooms in other nearby accessible cars and regardless of whether the “restroom for other passengers” is on the same floor as the accessible seating locations. It states:</dt>

                            <dd>Restrooms, complying with § 38.123 shall be provided in single-level rail passenger coaches and food services cars adjacent to the accessible seating locations required by paragraph (d) of this section. Accessible restrooms are required in dining and lounge cars only if restrooms are provided for other passengers.</dd>

                            <dt><strong>38.111(a)(6)</strong> requires each individual sleeper car to have accessible accommodations, regardless of the number or location of accessible bedrooms elsewhere on the train. It states:</dt>
                            <dd>Sleeper cars shall comply with §§ 38.113 (b) through (d), 38.115 through 38.121, and 38.125 of this part, and have at least one compartment which can be entered and used by a person using a wheelchair or mobility aid and complying with § 38.127 of this part.</dd>

                            <dt><strong>38.111(d)</strong> requires individual coaches to have a specific number of accessible spaces, regardless of the number or location of accessible spaces elsewhere on the train. It states:</dt>
                            <dd>Passenger coaches or food service cars shall have the number of spaces complying with § 38.125(d)(2) of this part and the number of spaces complying with § 38.125(d)(3) of this part, as required by § 37.91 of this title.</dd>

                            <dt><strong>38.125(a)</strong> requires each individual rail car to have an accessible path from the platform to the accessible seating location, even for cars that are part of fixed trainsets with accessible spaces and accommodations on the upper level, with multiple elevators connected by an accessible path, as proposed in the Alternative Design Standards described herein. It states:</dt>

                            <dd>(1) General. All intercity rail cars, other than level entry cars, required to be accessible by §§ 38.111 (a) and (e) of this subpart shall provide a level-change mechanism or boarding device (e.g., lift, ramp or bridge plate) complying with either paragraph (b) or (c) of this section and sufficient clearances to permit a wheelchair or other mobility aid user to reach a seating location complying with paragraph (d) of this section.</dd>

                            <dd>(2) Exception. If level-entry boarding, portable or platform lifts, ramps or bridge plates meeting the applicable requirements of this section are provided on station platforms or other stops required to be accessible, or mini-high platforms complying with § 38.113(d) are provided, the car is not required to be equipped with a car-borne device. The access systems or devices used at a station to which section 37.42 applies must permit compliance with that section.</dd>

                            <dt><strong>38.125(d)(1)</strong> requires individual rail cars to have a specific number of accessible spaces, regardless of the number or location of accessible spaces elsewhere on the train. It states:</dt>
                            <dd>Requirements. All intercity rail cars required to be accessible by §§ 38.111 (a) and (e) of this subpart shall provide at least one, but not more than two, mobility aid seating location(s) complying with paragraph (d)(2) of this section; and at least one, but not more than two, seating location(s) complying with paragraph (d)(3) of this section which adjoin or overlap an accessible route with a minimum clear width of 32 inches.</dd>
                        </dl>

                    </li>

                    <li><strong>Alternate Method of Compliance</strong>

                        <p>Amtrak proposes to comply with the following Alternative Design Standards as an alternative method of compliance, providing equal or better accessibility and usability of its trainsets, as further described below in Section IV.</p>

                        <ol>
                            <li className='list-decimal'><strong>Trainset / Semi-Permanent Coupling</strong></li>
                            <p className=''>These Alternative Design Standards are based on the concept of an accessible core trainset offering enhanced mobility between cars and access to train amenities, and utilizing semi-permanent coupling.</p>
                            <li className='list-decimal'><strong>Number of Accommodations</strong>
                                <ol>
                                    <li className='ml-5 list-[lower-alpha] font-normal'>The number of accessible coach spaces in a trainset will be equal to or greater than the number of coach cars in the trainset.</li>
                                    <li className='ml-5 list-[lower-alpha] font-normal'> The number of accessible bedrooms in a trainset will be equal to or greater than the number of sleeper cars in the trainset (not including sleeper cars used primarily for crews or other non-revenue purposes).</li>
                                </ol>
                            </li>
                            <li className='list-decimal'><strong>Access to Amenities</strong>
                                <p>All accessible coach spaces and all accessible bedrooms will be located along or within an accessible core area of the trainset, with access to dining, cafe and lounge cars and similar amenities, via an accessible 32” wide path.
                                </p>
                            </li>

                            <li className='list-decimal'><strong>Priority Positions</strong>
                                <ol>
                                    <li className='ml-5 list-[lower-alpha] font-normal'>Accessible sleeper cars will have priority over any non-accessible sleeper cars in terms of proximity to dining, café and lounge cars.</li>
                                    <li className='ml-5 list-[lower-alpha] font-normal'>Accessible coaches will have priority over any non-accessible coaches in terms of proximity to dining, café and lounge cars.</li>
                                </ol>
                            </li>

                            <li className='list-decimal'><strong>Access to Redundant Restrooms</strong>
                                <p>All accessible spaces will have access to at least two accessible restrooms, via an accessible 32” wide path.</p>
                            </li>

                            <li className='list-decimal'><strong>Access to Redundant Vertical Access Points</strong>
                                <p>All accessible coach spaces and accessible bedrooms on the upper level of a bi-level train will have access, via an accessible 32” wide path, to at least two accessible elevators to provide access to the platform for boarding and deboarding.</p>
                            </li>
                        </ol>
                    </li>


                    <li><strong>Demonstration of How Proposed Design Standards Meet or Exceed the Level of Accessibility or Usability of the Train</strong>

                        <p>The ADA regulations listed above require certain accessible accommodations in each individual rail car. For trains that are made up of rail cars that are regularly connected to others and disconnected, having each car be accessible is a practical rule that ensures sufficient access to accessible seating locations and other accommodations. However, while this rule ensures that each railcar is technically accessible, each individual accessible location is “isolated,” not connected to other accessible locations, without access to a redundant restroom, and without direct (or often any) access to other parts of the train, such as dining, lounge, café or observation cars.</p>

                        <p>The Alternative Design Standards proposed herein are for “trainsets,” or trains that utilize “semi-permanent coupling.” The cars that make up these trainsets are not easily or regularly disconnected from each other, but rather, they are connected in fixed arrangements via connections that are not designed to be easily separated. This type of connection (similar type of connection used by Amtrak on its Acela trainsets) has many benefits, including smoother transitions for passengers traversing from one car to another.</p>

                        <p>Because of the unique nature of trainsets, Amtrak proposes that they should rightly be considered as a whole and not as the sum of their individual rail car parts. In other words, the accessibility of a trainset should be viewed and considered when it is fully "assembled.”</p>

                        <p>If the Alternative Design Standards listed here are approved, Amtrak can provide a much more accessible and enjoyable train experience for customers with mobility disabilities than what the current regulations provide. Instead of isolated accessible positions, Amtrak can provide accessible accommodations on the upper level of bi-level trains, with an accessible path connecting multiple seating spaces and restrooms, and access to the amenities that are central to Amtrak’s Long Distance journeys, such as dining cars, lounge cars, café cars, and the opportunity to take advantage of the expansive windows often provided on the upper level of bi-level train cars to enjoy the scenery. Amtrak can also locate accessible positions in priority locations, closer to the dining and lounge cars, making it easier for customers with mobility disabilities to get to those amenities.</p>

                    </li>

                </ol>
            </section>
        </Fragment>
    )
}

export default FacilitationRequest