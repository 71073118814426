import React, { Fragment } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

const FooterTerms = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    return (
        <Fragment>
            <button className='text-[#1E4D6B] text-lg font-semibold underline uppercase tracking-wide mx-auto
            ' ref={finalRef} aria-label="Terms and Conditions" onClick={onOpen}>
                Terms & Conditions
            </button>

            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Terms & Conditions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Thank you for your interest in the Amtrak Public Hearing on Train Accessibility.  Due to the nature and purpose of the Public Hearing, the comments you submit to the Public Hearing will not be confidential and will be accessible by the public.  To best protect your privacy, Amtrak will not share any of your personal information, such as your Name and Email Address, with outside parties for the purposes of sale, promotion, or marketing.  Registration for the Public Hearing and submission of Public Hearing comments is limited to residents of the United States.
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Fragment>
    )
}

export default FooterTerms
