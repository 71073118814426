import Wheelchair from '../images/abt.webp'

// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import React from 'react';
// import { Keyboard, Pagination, Navigation, Mousewheel, A11y } from 'swiper/modules';

const AboutThisProject = () => {


    return (
        <article className='py-20' id='about'>

            <header>
                <h2 className='sub-title'>about this project</h2>
            </header>

            <figure className="image h-[70vw]
            sm:[60vw]
            lg:h-[40vw]
            ">
                <img className='object-cover w-full h-full' src={Wheelchair} alt="A wheelchair user and their travel companion on a train." />
            </figure>

            <div className='max-w-[770px] mx-auto px-10'>

                <header>
                    <h3 className='text-slate-800 text-[42px] font-semibold leading-[52px] my-[28px]'>Notice of Public Hearing</h3>
                </header>

                <p className='body-text'>Amtrak held a public hearing on August 30, 2023, in Washington, D.C. and online via ZOOM as a forum for members of the public to provide commentary on accessible train travel.</p>
                
                <p className='text-[22px] my-5 font-extrabold'>More information about the public hearing can be found<a href='#event' aria-label="Click here for public hearing information" className=' underline'> here.</a></p>
                    

                {/* <p className='text-[22px] font-extrabold'>The public comment period has been extended to 5 p.m. Eastern Time on September 8, 2023. Please submit your comments<a href='#form' aria-label="Click here to submit your comment" className=' underline'> here.</a></p> */}

            </div>


        </article>
    )
}

export default AboutThisProject