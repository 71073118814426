import React from 'react'

const Socials = () => {
    return (
        <nav aria-label="Social Media Links" className='flex justify-center gap-3 mt-10'>


            <a className="" title="TikTok" href="https://www.tiktok.com/@amtrak" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/tiktok2.svg" alt="TikTok" />
            </a>

            <a className="" title="Amtrak on Facebook" href="https://www.facebook.com/amtrak" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/ui/facebook.svg" alt="Amtrak on Facebook" />
            </a>

            <a className="" title="Amtrak on Twitter" href="https://www.twitter.com/amtrak" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/ui/twitter.svg" alt="Amtrak on Twitter" />
            </a>

            <a className="" title="Amtrak on Instagram" href="https://www.instagram.com/amtrak" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/ui/instagram.svg" alt="Amtrak on Instagram" />
            </a>

            <a className="" title="Amtrak on Linkedin" href="https://www.linkedin.com/company/amtrak" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/ui/linkedin.svg" alt="Amtrak on Linkedin" />
            </a>

            <a className="" title="Amtrak on YouTube" href="https://www.youtube.com/amtrak" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/ui/youtube.svg" alt="Amtrak on YouTube" />
            </a>

            <a className="" title="Pinterest" href="https://www.pinterest.com/amtraktravels/" target="_blank" data-automation-id="pageFooterSocialLink" rel="noreferrer">
                <img className="w-[50px] hover:opacity-70 duration-300" src="https://www.amtrak.com/content/dam/projects/dotcom/english/public/images/icons/ui/pinterest.svg" alt="Pinterest" />
            </a>

        </nav>
    )
}

export default Socials






