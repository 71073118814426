import React from 'react'
import Logo from '../images/svg/amtrak-logo.svg'
import Socials from './socials'
import FooterTerms from './footerTerms'
// import Gdpr from './gdpr'


const Footer = () => {
    return (
        <footer className='h-[380px] bg-[#E7E9EB] flex justify-center text-center px-5'>


            <div className='max-w-[550px] my-auto '>

                <div className='flex mb-7
                 min-[435px]:
                '>
                    <FooterTerms />
                    {/* <Gdpr /> */}
                </div>

                <img src={Logo} alt="Amtrak Logo" className='footer-logo w-[360px] mx-auto m-3' />
                <small className='text-sm'>&copy; 2023 National Railroad Passenger Corporation</small>
                <Socials />
            </div>
        </footer>
    )
}

export default Footer